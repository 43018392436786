import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Layout, ConfigProvider } from 'antd';
import './i18n';
import { ThemeProvider, useTheme } from '@context/ThemeContext';

import { getCompany, getCompanyHomePage, capitalize } from '@helpers/common';
import Auth from '@auth/Auth';
import Logout from '@auth/Logout';
import Login from '@auth/Login';
import Signup from '@auth/Signup';
import ReferrerRedirect from '@auth/ReferrerRedirect';
import Callback from '@auth/Callback';
import PrivateRoute from '@utils/PrivateRoute';
import Places from '@Components/Places/Places';
import Businesses from '@Components/Businesses/Businesses';
import Profile from '@Components/Profile/Profile';
import Invoices from '@Components/Invoices/Invoices';
import BillingInformation from '@Components/Profile/BillingInformation';
import Integrations from '@Components/Profile/Integrations';
import ApiDocs from '@Components/ApiDocs/ApiDocs';
import Checkouts from '@Components/Checkouts/Checkouts';
import CheckoutResults from '@Components/Common/CheckoutResults';
import CheckoutHistory from '@Components/Common/CheckoutHistory';
import MakeCryptoPayment from '@Components/Profile/AddCredits/Crypto/MakeCryptoPayment';
import CryptoPayment from '@Components/Profile/AddCredits/Crypto/CryptoPayment';
import BusinessesHeader from '@Components/Navigation/BusinessesHeader';
import Header from '@Components/Navigation/Header';

function AppContent() {
  const auth = new Auth();
  const location = useLocation();
  const { pathname } = location;
  const { authenticated, profile } = auth;
  const { ie } = profile;
  const { algorithm } = useTheme();

  const PLAIN_PAGES = new Set([
    '/checkout-history', '/local-businesses', '/businesses', '/places', '/companies', '/checkout-results'
  ]);

  const showSider = !PLAIN_PAGES.has(pathname);

  const homePage = getCompanyHomePage();
  const company = capitalize(getCompany());

  const handleAuthentication = ({ location }) => {
    if (/access_token|id_token|error/.test(location.hash)) {
      auth.handleAuthentication();
    }
  };

  useEffect(() => {
    const sessionCheck = () => {
      if (localStorage.getItem('isLoggedIn') === '1') {
        auth.renewSession();
      }
    };

    if (localStorage.getItem('isLoggedIn') === '1') {
      sessionCheck();
    }

    const intervalId = setInterval(sessionCheck, 1000 * 60 * 20);
    return () => clearInterval(intervalId);
  }, [auth]);

  return (
    <ConfigProvider theme={{ algorithm }}>
      {ie && <>God Mode: {ie}</>}
      {showSider ?
        <Layout style={{ minHeight: '100vh' }}>
          <Header auth={auth} />
          <Layout>
            <Layout.Content>
              <Routes>
                <Route path='/' element={authenticated ? <Navigate to='/profile' /> : <Navigate to='/login' />} />
                <Route path='/profile' element={<PrivateRoute auth={auth} component={Profile} authForwarded={auth} />} />
                <Route path='/profile/crypto-payment' element={<PrivateRoute auth={auth} component={MakeCryptoPayment} />} />
                <Route path='/profile/crypto-payment/:paymentId' element={<PrivateRoute auth={auth} component={CryptoPayment} />} />
                <Route path='/integrations' element={<PrivateRoute auth={auth} component={Integrations} />} />
                <Route path='/exports' element={<PrivateRoute auth={auth} component={Checkouts} />} />
                <Route path='/billing-info' element={<PrivateRoute auth={auth} component={BillingInformation} />} />
                <Route path='/invoices' element={<PrivateRoute auth={auth} component={Invoices} />} />
                <Route path='/api-docs' element={<ApiDocs auth={auth} />} />

                <Route path='/login' element={<Login auth={auth} />} />
                <Route path='/signup' element={<Signup auth={auth} />} />
                <Route path='/logout' element={<Logout auth={auth} />} />

                <Route path='/callback' element={<Callback handleAuthentication={handleAuthentication} />} />
              </Routes>
            </Layout.Content>
            <Layout.Footer style={{ textAlign: 'center', background: '#fff' }}>
              <a target='_blank' rel='noreferrer' href={homePage}>{`Copyright 2020-${new Date().getFullYear()} ${company} ©`}</a>
            </Layout.Footer>
          </Layout>
        </Layout> :
        <BusinessesHeader auth={auth} />
      }
      <Routes>
        <Route path='/checkout-history' element={<CheckoutHistory />} />
        <Route path='/local-businesses' element={<Places auth={auth} />} />
        <Route path='/businesses' element={<Businesses auth={auth} />} />
        <Route path='/places' element={<Places auth={auth} />} />
        <Route path='/companies' element={<Businesses auth={auth} />} />
        <Route path='/refer' element={<ReferrerRedirect />} />
        <Route path='/checkout-results' element={<CheckoutResults />} />
      </Routes>
    </ConfigProvider>
  );
}

function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}

export default App;
