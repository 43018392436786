import React, { useEffect, useState } from 'react';
import { Table, Button, notification, Space } from 'antd';
import { ReactCountryFlag } from 'react-country-flag';
import Moment from 'react-moment';
import { useTranslation, Trans } from 'react-i18next';

import { toArray } from '@utils/helper';
import apiProfile from '@api/apiProfile';
import Categories from '@Common/Categories';
import Address from '@Common/Address';

export default function Checkouts() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const tasksColumns = [
    {
      title: t('title.what'),
      dataIndex: 'id',
      width: 200,
      key: 'id',
      render: (id, { payload }) => <>
        <Categories value={payload.search_params.t ? toArray(payload.search_params.t) : [t('exports.allCategories')]} />
      </>
    },
    {
      title: t('title.where'),
      dataIndex: 'id',
      width: 150,
      key: 'id',
      render: (id, { payload }) => <>
        <div title={payload.search_params.cc}>
          <ReactCountryFlag countryCode={payload.search_params.cc} /> <Address value={payload.search_params} />
        </div>
      </>
    },
    {
      title: t('title.when'),
      dataIndex: 'created_at',
      width: 250,
      key: 'created_at',
      render: created_at => <>{created_at && <Moment utc local fromNow>{created_at}</Moment>}</>,
    },
    {
      title: t('title.quantity'),
      dataIndex: 'id',
      width: 150,
      key: 'id',
      render: (id, { payload }) => <>{payload.quantity}</>
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      render: (id, { payload }) => (
        <Space>
          <a key='results' target='_blank' rel='noreferrer' href={payload.success_url}>
            <Button
              size='small'
              type='primary'
              title={`About ${payload.quantity} rows`}
            >
              <Trans i18nKey='title.downloadResults' />
            </Button>
          </a>
          <a key='search' target='_blank' rel='noreferrer' href={payload.current_url}>
            <Button
              size='small'
              type='default'
              title={`About ${payload.total} rows`}
            >
              <Trans i18nKey='title.toSearch' />
            </Button>
          </a>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    onFetchData();
  }, []);

  async function onFetchData() {
    setLoading(true);
    try {
      const data = await apiProfile.getCheckouts();
      setData(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return <>
    <Table
      rowKey={record => `${record.created_at}${record.amount_total}`}
      size='small'
      scroll={{ x: 'max-content' }}
      loading={loading}
      pagination={false}
      columns={tasksColumns}
      dataSource={data}
    />
  </>;
}
