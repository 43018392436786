import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Space, Statistic } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import AddCredits from './AddCredits/AddCredits';

export default function Balance({ loading, profile, onPaymentMade, emailVerified }) {
  const { balance, coupon = {}, risk_level = 0 } = profile;
  const { duration_in_months, duration } = coupon;
  const couponDuration = duration_in_months ? `for ${duration_in_months} months` : duration;
  const { name: couponName } = coupon;
  const { t } = useTranslation();

  const statisticTitle = <h2 className='h2'>{t('profile.remainingCredits')}</h2>;

  return <Space direction='vertical' size={32} className='first-space-item-full'>
    <Space className='space-items-full' align='stretch' wrap>
      <Row gutter={[16, 16]} className='row-space'>
        <Col xs={24} lg={8} xl={6} xxl={5}>
          <Card bordered={false} className='statistic-card'>
            <Statistic
              prefix='$'
              value={balance}
              precision={2}
              title={statisticTitle}
              loading={loading}
            />
          </Card>
        </Col>
        {couponName && (
          <Col xs={24} lg={12}>
            <Card bordered={false} className='statistic-card'>
              <Statistic
                title={<h2 className='h2'>{t('profile.specialOffer')}</h2>}
                value={couponName}
                suffix={couponDuration}
              />
            </Card>
          </Col>
        )}
      </Row>
    </Space>
    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'>{t('title.topUp')}</h3>
      <AddCredits loading={loading} callback={onPaymentMade} riskLevel={risk_level} emailVerified={emailVerified} />
      {!balance || balance <= 0 && <>
        <p className='help-p2'>
          * <Trans i18nKey='profile.usingCredits' />.
        </p>
      </>}
    </Space>
  </Space>;
}

Balance.propTypes = {
  loading: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  onPaymentMade: PropTypes.func.isRequired,
  emailVerified: PropTypes.bool,
};
