import React from 'react';
import PropTypes from 'prop-types';
import { Input, message, Button, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

import Mixpanel from '@analytics/mixpanel';
import { getCompanyHomePage } from '@helpers/common';
import { useMedia } from '@hooks/useMedia';

const HOME_PAGE = getCompanyHomePage();

export default function ReferralProgram({ userId, disabled, referred }) {
  const referrer = userId ? btoa(userId) : '';
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  function onClickFromInput() {
    onCopy();
  }

  function onCopy() {
    Mixpanel.track('Copy referral link');

    let referrerLink = document.querySelector('.referrer-link');
    referrerLink.select();
    document.execCommand('copy');

    message.success(t('referralProgram.copyLink'));
  }

  return <Space className='w-100' direction={isDesktop ? 'horizontal' : 'vertical'}>
    <Space className='first-space-item-full'>
      <Input
        readOnly
        className='referrer-link copieble w-100'
        value={`${HOME_PAGE}/refer?referrer=${referrer}`}
        disabled={disabled}
        onClick={onClickFromInput}
      />
      <Button  className='noPadding'
        title={t('copyToClipboard')}
        icon={<FontAwesomeIcon icon={faCopy} />}
        onClick={onCopy}
      />
    </Space>
    {referred && <span className='textNoWrap'><Trans i18nKey='referralProgram.clients'/>: {referred.toLocaleString()}</span>}
  </Space>;
}

ReferralProgram.propTypes = {
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  referred: PropTypes.number,
};
