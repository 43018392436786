import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { notification, message, Select, Space, Alert } from 'antd';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { fetchProfile, removeProfile, makePaymentNMI } from '@redux/actions/profileActions';
import Href from '@shared/Components/Href';
import ProfileDangerousActions from './ProfileDangerousActions';
import UpcomingInvoice from './UpcomingInvoice';
import PromotionCode from './PromotionCode';
import ReferralProgram from './ReferralProgram';
import SupportBlock from './SupportBlock';
import EmailAddress from './EmailAddress';
import Tenants from './Tenants';
import Balance from './Balance';
import APIToken from './APIToken';
import { useMedia } from '@hooks/useMedia';

const { Option } = Select;

const appLanguages = [
  { code: 'en', label: 'English' },
  { code: 'de', label: 'Deutsch' },
  { code: 'es', label: 'Español' },
  { code: 'fr', label: 'Français' },
  { code: 'it', label: 'Italiano' },
  { code: 'br', label: 'Português (Brasil)' },
  { code: 'ru', label: 'Русский' },
  { code: 'tr', label: 'Türkçe' },
  { code: 'uk', label: 'Українська' },
  { code: 'zh', label: '中文' },
  { code: 'ar', label: 'العربية' },
  { code: 'hi', label: 'हिन्दी' },
  { code: 'jp', label: '日本語' },
  { code: 'ko', label: '한국어' },
  { code: 'pl', label: 'Polski' },
  { code: 'th', label: 'ไทย' },
  { code: 'vi', label: 'Tiếng Việt' }
];

export default function Profile({ authForwarded }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isDesktop } = useMedia();

  const { search = {} } = location;
  const { profile: authProfile } = authForwarded || {};
  const { emailVerified, email, roles = [], provider, userId } = authProfile || {};
  const isSupport = roles.includes('support');
  const { secondary_tab, deposite, deposited } = queryString.parse(search);
  const isSecondaryTab = secondary_tab && secondary_tab === 'true';

  const profile = useSelector(state => state.profileReducer.profile);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { account_status, api_token, coupon = {}, upcoming_invoice = {}, referred, tenants = [], is_tenant } = profile;
  const isTenant = is_tenant;
  const { name: couponName } = coupon;
  const accountNotValid = account_status !== 'valid';

  const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);

  useEffect(() => {
    document.title = t('title.profile');

    if (deposite) {
      handleOnMakePayment(parseInt(deposite), isSecondaryTab);
    } else if (deposited === 'true') {
      onFetchProfile();
      if (isSecondaryTab) {
        message.success(t('profile.message.returnToPreviousTab'), 30);
        setTimeout(() => window.close(), 1000);
      }
    }
    else {
      onFetchProfile();
    }
  }, []);

  async function onPaymentMade() {
    await onFetchProfile();
    if (isSecondaryTab) {
      setTimeout(() => window.close(), 1000);
    }

  }

  async function handleOnMakePayment(amount, secondaryTab) {
    onMakePayment(amount, secondaryTab);
  }

  function onMakePayment(amount, secondaryTab = false) {
    dispatch(makePaymentNMI(amount, secondaryTab));
  }

  function onFetchProfile() {
    return dispatch(fetchProfile());
  }

  async function onPasswordChangeRequest() {
    setPasswordChangeRequested(true);
    const cb = function () {
      notification.success({
        message: t('profile.messages.checkEmailForPasswordChange'),
        duration: 7
      });
    };
    await authForwarded.requestPasswordChange(cb);
  }

  function onProfileRemove() {
    dispatch(removeProfile(navigate));
  }

  function onEmailChanged() {
    authForwarded.logout();
  }

  function changeLanguage(value) {
    i18n.changeLanguage(value);

    const updatedUrl = value !== 'en' ? `${window.location.pathname}?ln=${value}` : window.location.pathname;

    window.location.href = updatedUrl;
  }

  return <Space direction='vertical' size={32} className='w-100'>
    {isTenant && (
      <Alert
        message='Your profile is connected to the main company account and governed by it'
        type='info'
        showIcon
      />
    )}
    {!isTenant && <Balance loading={loading} profile={profile} onPaymentMade={onPaymentMade} emailVerified={emailVerified}/>}

    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='title.apiToken'/></h3>
      <p className='main-text'>
        <Trans i18nKey='profile.apiToken.description'/>
        <Href external href='/api-docs'>
          <Trans i18nKey='title.apiDocs'/>
        </Href>
        <Trans i18nKey='profile.apiToken.moreInfo'/>
      </p>
      <APIToken loading={loading} token={api_token} accountNotValid={accountNotValid}/>
      {account_status === 'no_default_source' &&
          <p className='hintText'>
            <Trans i18nKey='profile.buttonError'/>
          </p>
      }
    </Space>

    {!isEmpty(upcoming_invoice) && <Space className='w-100' direction='vertical' size={16}>
      <h3 className='h3 bold'><Trans i18nKey='title.upcomingInvoice' /></h3>
      <UpcomingInvoice loading={loading} value={upcoming_invoice} couponName={couponName} isSupport={isSupport}/>
    </Space>}

    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='profile.referralProgram.title'/></h3>
      <Space direction='vertical' className='w-100'>
        <p className='main-text'>
          <Trans i18nKey='profile.referralProgram.description1'/>
          <strong>25%</strong>
          <Trans i18nKey='profile.referralProgram.description2'/>
        </p>
        <Space align={isDesktop ? 'center' : 'baseline'} className='first-space-item-full'>
          <ReferralProgram userId={userId} disabled={loading} referred={referred}/>
        </Space>
      </Space>
    </Space>

    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='profile.referralProgram.title'/></h3>
      <Space direction='vertical' className='w-100'>
        <p className='main-text'>
          <Trans i18nKey='profile.referralProgram.description1'/>
          <strong>25%</strong>
          <Trans i18nKey='profile.referralProgram.description2'/>
        </p>
        <Space align={isDesktop ? 'center' : 'baseline'} className='first-space-item-full'>
          <ReferralProgram userId={userId} disabled={loading} referred={referred}/>
        </Space>
      </Space>
    </Space>

    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='title.promotionCode' /></h3>
      <PromotionCode existingCouponName={couponName}/>
    </Space>

    <Space direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='title.emailAddress'/></h3>
      <EmailAddress
        value={email}
        verified={emailVerified}
      />
    </Space>

    <Space align={isDesktop ? 'baseline' : 'unset'} direction='vertical' size={16} className='w-100'>
      <h3 className='h3 bold'><Trans i18nKey='title.language' /></h3>
      <Select
        style={{ minWidth: '182px' }}
        className='w-100'
        showSearch
        defaultValue={i18n.language || 'en'}
        optionFilterProp='children'
        onChange={changeLanguage}
      >
        {appLanguages.map(r => <Option key={r.code} value={r.code}>{r.label}</Option>)}
      </Select>
    </Space>

    {!isTenant && (<Space direction='vertical' size={16}>
      <h3 className='h3 bold'>Team</h3>
      <Tenants tenants={tenants} />
    </Space>)}

    {isSupport && <SupportBlock profile={authProfile} loading={loading} auth={authForwarded} />}

    <ProfileDangerousActions
      loading={loading}
      passwordChangeRequested={passwordChangeRequested}
      onPasswordChangeRequest={onPasswordChangeRequest}
      onEmailChanged={onEmailChanged}
      onRemove={onProfileRemove}
      authProvider={provider}
    />
  </Space>;
}

Profile.propTypes = {
  authForwarded: PropTypes.object.isRequired,
  auth: PropTypes.object,
};
