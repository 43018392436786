import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Typography, Space, Checkbox } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import Rating from './Rating';
import Address from '@Common/Address';
import { handleOnCopy } from '@utils/helper';
import PlaceTitle from './PlaceTitle';

import './Place.scss';
import {
  LocationStroke, Phone,
  PictureIcon, Link as LinkIcon,
  Email, DollarCircle, Building,
  People, InfoCircleThin
} from '@assets/icons';
import HidedValue from '@Common/HidedValue';
import PlaceTypes from './PlaceTypes';
import { deselectItem, selectItem } from '@redux/actions/selectActions';
import { useDispatch, useSelector } from 'react-redux';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

const { Link } = Typography;

const BUSINESS_STATUS = {
  Closedpermanently: { text: 'Permanently closed', color: '#ff4d4f' },
  Closedtemporarily: { text: 'Temporarily closed', color: '#faad14' },
  Operational: { text: 'Operational', color: '#52c41a' },
};

export default function PlaceCard({ value, onSearchParamsChange }) {
  const {
    os_id, photo, types, located_in, located_os_id, site, phone, phone_type, email_1, email_2, email_3, email_1_title,
    email_1_first_name,  email_2_title, email_3_title, corp_revenue, corp_employees, corp_founded_year,
    corp_is_public, business_status, address
  } = value;
  const [imageError, setImageError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation();
  const selectedItems = useSelector((state) => state.items.selectedItems || []);
  const dispatch = useDispatch();

  const isChecked = Array.isArray(selectedItems) && selectedItems.includes(os_id);
  const domain = site ? site.replace('www.', '').replace('http://', '').replace('https://', '').split('/')[0] : null;

  useEffect(() => {
    setImageError(false);
  }, [photo]);

  function handleLocatedInClick() {
    onSearchParamsChange({ located: located_os_id, type: null, located_in: located_in });
  }

  function onExport() {
    setModalVisible(!modalVisible);
  }

  const checkboxHandler = (e) => {
    const { checked: isSelected, value } = e.target;

    if (isSelected) {
      dispatch(selectItem(value));
    } else {
      dispatch(deselectItem(value));
    }
  };

  return (
    <Space size={16} direction='vertical' className={`card ${isChecked ? 'selected' : ''}`} onCopy={handleOnCopy}>
      <div className='image-wrapper'>
        {!imageError && photo ? (
          <img className='image' src={photo} alt='photo' referrerPolicy='no-referrer' onError={() => setImageError(true)} />
        ) : (
          <div className='no-image'>
            <PictureIcon />
          </div>
        )}
        <div className='image-overlay'>
          <div className='top-block'>
            <Checkbox
              onChange={checkboxHandler}
              value={os_id}
              checked={isChecked}
            />
            <Rating value={value}/>
          </div>
          <PlaceTypes types={types}/>
        </div>
      </div>
      <Space size={16} direction='vertical' className='info-container'>
        <PlaceTitle value={value} onExport={onExport} />
        <Space size={24} direction='vertical' className='w-100 h-100 justifySpaceBetween'>
          <Space size={0} className='main-info w-100' direction='vertical'>
            {address &&
                <Space size={4} direction='vertical'>
                  <Space size={6} align='center' className='align-items-space'>
                    <LocationStroke />
                    <Address value={value} />
                  </Space>
                  {located_in && <Space align='center' size={0} className='align-items-space pl-24'>
                    <Link onClick={handleLocatedInClick}>({located_in})</Link>
                  </Space>}
                </Space>
            }
            {domain && <Space align='center' size={6} className='align-items-space'>
              <LinkIcon />
              <HidedValue value={`https://${domain}`} />
            </Space>}
            {phone && <Space align='center' size={6} className='align-items-space'>
              <Phone />
              <HidedValue value={phone} />
              {phone_type && <span className='main-text'>({phone_type})</span>}
            </Space>}
            {email_1 && (
              <Space align='center' className='email-block align-items-space'>
                <Email/>
                <span className='main-text text-block'>
                  <span className='text-truncate'>
                    <HidedValue value={email_1}/>
                    {email_1_title && `(${email_1_title}:`}
                    <HidedValue value={email_1_first_name}/>
                  </span>

                  {email_2 && (
                    <>
                      {') '}
                      <Tooltip
                        className='additional-emails-tooltip'
                        title={
                          <Space direction='vertical' className='w-100'>
                            <Space align='center' size={6} className='additional-email align-items-space'>
                              <Email className='email-icon'/>
                              <span>
                                {email_2}
                                {email_2_title && ` (${email_2_title})`}
                              </span>
                            </Space>
                            {email_3 && (
                              <Space align='center' size={6} className='additional-email align-items-space'>
                                <Email className='email-icon'/>
                                <span>
                                  {email_3}
                                  {email_3_title && ` (${email_3_title})`}
                                </span>
                              </Space>
                            )}
                          </Space>
                        }
                      >
                        <span className='more-emails'>+ {email_3 ? '2' : '1'} more</span>
                      </Tooltip>
                    </>
                  )}
                </span>
              </Space>
            )}
          </Space>
          <Space direction='vertical' className='additional-info'>
            <Tooltip title={t('cardInfo')}>
              <Space wrap={true}>
                {/*{corp_founded_year &&*/}
                {/*    <Space size={6} className='main-text'>*/}
                {/*      <MdCorporateFare />*/}
                {/*      <strong><Trans i18nKey='title.founded' />:</strong>*/}
                {/*      {corp_founded_year}*/}
                {/*    </Space>}*/}
                {corp_employees &&
                  <Space size={6} className='align-items-space'>
                    <People/>
                    <span className='main-text'><Trans i18nKey='title.employees'/>:</span>
                    <span className='main-text bold'>{formatter.format(corp_employees)}</span>
                  </Space>}
                {corp_revenue &&
                  <Space size={6} className='align-items-space'>
                    <DollarCircle/>
                    <span className='main-text'><Trans i18nKey='title.revenue'/>:</span>
                    <span className='main-text bold'>{formatter.format(corp_revenue)}</span>
                  </Space>}
                {(corp_is_public === true || corp_is_public === false) &&
                <Space size={6} className='align-items-space'>
                  <Building />
                  <span className='main-text'><Trans i18nKey='title.type' />:</span>
                  <span className='main-text bold'>{corp_is_public ? 'Public' : 'Private'}</span>
                </Space>}
              </Space>
            </Tooltip>
            {business_status && (
              <Space size={6} className='align-items-space'>
                <InfoCircleThin />
                <span className='main-text'>Status:</span>
                <span
                  className='main-text bold'
                  style={{ color: BUSINESS_STATUS[business_status]?.color || 'black' }}
                >
                  {BUSINESS_STATUS[business_status]?.text}
                </span>
              </Space>
            )}

          </Space>
        </Space>
      </Space>
    </Space>
  );
}

PlaceCard.propTypes = {
  value: PropTypes.object.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
