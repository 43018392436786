import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip } from 'antd';
import {
  DollarCircleOutlined, TeamOutlined, FacebookOutlined, LinkedinOutlined, TwitterOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faGlobe, faPhone } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

import CheckoutModal from '@Common/CheckoutModal/CheckoutModal';
import Address from '@Common/Address';
import Tags from '@Common/Tags';
import Contacts from '@Common/Contacts';
import ExportOneButton from '@Common/ExportOneButton';
import HidedValue from '@Common/HidedValue';
import { handleOnCopy } from '@utils/helper';

const LeftDiv = styled.div`
  float: right;
  font-size: 12px;
`;

const RightDiv = styled.div`
  max-width: 950px;
`;

const FULL_DATA_TIP = 'Click the "Export" button to get this data.';

export default function BusinessCard({ value, onSearchParamsChange }) {
  const {
    _id,
    name, industry, sic, founded, alexa_ranking,
    domain, website_generator, phone, phone_type, linkedin, facebook, twitter,
    sales, size, contacts = [],
  } = value;
  const contactsAmount = contacts ? contacts.length : 0;
  const [modalVisible, setModalVisible] = useState(false);

  function handleTagClick(tag) {
    onSearchParamsChange({ type: tag });
  }

  function onExport() {
    setModalVisible(!modalVisible);
  }

  function onCopy(e) {
    if (!handleOnCopy(e)) {
      onExport();
    }
  }

  return (
    <Card bordered={false} onCopy={onCopy}>
      <LeftDiv>
        {size && <>
          <TeamOutlined /> <strong>Employees:</strong> from {size.f} to {size.t}<br/>
        </>}
        {sales && <>
          <DollarCircleOutlined /> <strong>Sales:</strong> {sales}<br/>
        </>}
        {sic && <><strong>SIC:</strong> {sic}<br/></>}
        {founded && <><strong>Founded:</strong> {founded}<br/></>}
        {alexa_ranking && <><strong>Alexa ranking:</strong> {alexa_ranking}<br/></>}
        <br/><br/>
      </LeftDiv>

      <h4 onCopy={handleOnCopy}>
        {name}&nbsp;
        <Tooltip title={FULL_DATA_TIP}>
          {facebook && <FacebookOutlined />} {linkedin && <LinkedinOutlined />} {twitter && <TwitterOutlined />}
        </Tooltip>
      </h4>

      <><FontAwesomeIcon icon={faLocationDot} />&nbsp;
        <strong>Address:</strong>&nbsp;
        <Address value={value} />
      </><br/>
      {domain && <><FontAwesomeIcon icon={faGlobe} /> <strong>Website:</strong> <HidedValue value={`https://${domain}`}/> {website_generator ? `(${website_generator})` : ''}<br/></>}
      {phone && <><FontAwesomeIcon icon={faPhone} /> <strong>Company phone:</strong> <HidedValue value={phone}/> {phone_type && <>({phone_type})</>}<br/></>}

      <br/><Tags value={industry} onClick={handleTagClick}/><br/>

      <br/>
      <RightDiv>
        <Contacts value={contacts} />
      </RightDiv>

      <ExportOneButton
        title={`Export ${name} contacts (${contactsAmount})`}
        onClick={onExport}
      />

      <CheckoutModal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        searchParams={{ _id }}
        totalResults={1}
        prelaodProfile={false}
        productName='Companies And Executives'
        units='businesses'
      />
    </Card>
  );
}

BusinessCard.propTypes = {
  value: PropTypes.object.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
