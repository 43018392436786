import React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { getCompanyHomePage } from '@helpers/common';

const HOME_PAGE = getCompanyHomePage();

export default function ReferrerRedirect() {
  const location = useLocation();
  const { search } = location;
  const { referrer, redirect = '' } = queryString.parse(search);

  if (referrer) {
    localStorage.setItem('referrer', referrer);
  }

  window.location.replace(redirect ? `${HOME_PAGE}/${redirect}` : HOME_PAGE);

  return <h5>Loading...</h5>;
}
