import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Space, Tooltip, Select, Switch } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';

import Mixpanel from '@analytics/mixpanel';
import SelectType from '@Common/SelectType';
import SelectCityOrTown from '@Common/SelectCityOrTown';
import SelectCounty from '@Common/SelectCounty';
import SelectCountry from '@Common/SelectCountry';
import SelectState from '@Common/SelectState';
import ExportButton from '@Common/ExportButton';
import SearchStatus from '@Common/SearchStatus';
import AdvancedFiltersModal from '../AdvancedFiltersModal/AdvancedFiltersModal';
import './Search.scss';

// const mobileFilterCountryCodes = new Set([
//   'IT', 'UA', 'FR', 'ES', 'SE', 'SZ', 'TR', 'AE', 'BR',
//   'IN', 'ID', 'PH', 'IE', 'IL', 'PL', 'PT', 'RU', 'SG',
//   'ZA', 'TW', 'TH', 'VN', 'CO', 'EG', 'HK', 'MY', 'NL',
//   'NZ', 'PE', 'CL', 'US',
// ]);

const advancedParameterKeys = [
  'addedFrom', 'postalCode', 'price', 'rating', 'reviews', 'revenue', 'employees', 'businessStatus',
  'public', 'verified', 'email', 'contactCategory', 'phone', 'emailAndPhone', 'site', 'domain',
  'name', 'it', 'att', 'os_id', 'area', 'generator', 'businessOnly'
];

export default function Search({ searchParams, onChange, size, loading, onSearch, exporting, total, loadingTotal, hideControls, searched, setSearched, setIsMapVisible, isMapVisible, placesShowing }) {
  const { cc, state, c, county, t, geoFilters = [], located_in } = searchParams;
  const exportDisabled = loading || loadingTotal || total === 0 || placesShowing === 0;
  const advancedParametersCount = advancedParameterKeys.filter((key) => searchParams[key]).length;
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const { t: translate } = useTranslation();

  function onSearchHandle() {
    onSearch({ ...searchParams });
    setSearched(true);
    Mixpanel.track('Search click', searchParams);
  }

  function onChangeHandle(value) {
    onChange(value);
    setSearched(false);
  }

  function onCountryChange(countryCode) {
    delete searchParams.state;
    delete searchParams.c;
    delete searchParams.county;
    delete searchParams.mobile;

    onChangeHandle({ ...searchParams, cc: countryCode });
  }

  function onStateChange(newState) {
    delete searchParams.c;
    delete searchParams.county;
    onChangeHandle({ ...searchParams, state: newState });
  }

  const handleFilterOpenModal = () => {
    setIsFiltersModalOpen(true);
  };

  const handleFilterCloseModal = () => {
    setIsFiltersModalOpen(false);
  };

  const handleClearGeoFilters = () => {
    onChangeHandle({ ...searchParams, geoFilters: [] });
  };

  const handleClearLocatedFilters = () => {
    delete searchParams.located;
    delete searchParams.located_in;
    onChangeHandle({ ...searchParams });
  };

  const handleMapVisible = () => {
    if (isMapVisible) {
      handleClearGeoFilters();
    } else {
      handleClearLocatedFilters();
    }
    setIsMapVisible(!isMapVisible);
  };

  return <Space size={16} direction={'vertical'} className='w-100'>
    <Form name='search-form' className='top-search-bar'>
      {hideControls ? (
        <Tooltip
          title={
            searched
              ? loadingTotal && 'Calculating results'
              : 'Apply filters by clicking search'
          }
        >
          <ExportButton
            productName='Places'
            type={searched ? 'primary' : 'default'}
            totalResults={total}
            loading={exporting || loadingTotal}
            searchParams={searchParams}
            disabled={exportDisabled || !searched}
          />
        </Tooltip>
      ) : (
        <>
          <Space size={8} className='filters-block w-100' wrap align='baseline'>
            <SelectType
              showQucikButtons
              size='large'
              value={t}
              onChange={(v) => onChangeHandle({ ...searchParams, t: v })}
            />

            {isMapVisible ? (
              <Tooltip title={geoFilters.length === 0 ? 'Use the buttons on the map below to draw a selection' : ''}>
                <Select
                  size='large'
                  placeholder={`Current Map Area${geoFilters.length > 0 ? ` (${geoFilters.length})` : ''}`}
                  suffixIcon={geoFilters.length > 0 && <CloseOutlined onClick={handleClearGeoFilters} />}
                  open={false}
                />
              </Tooltip>
            ) : located_in ? (
              <Select
                size='large'
                value={located_in}
                suffixIcon={located_in && <CloseOutlined onClick={handleClearLocatedFilters} />}
                open={false}
              />
            ) :(
              <>
                <SelectCountry value={cc} onChange={onCountryChange} />

                {cc === 'US' && (
                  <SelectState
                    placeholder='State'
                    value={state}
                    onChange={onStateChange}
                  />
                )}

                <SelectCityOrTown
                  isPlaces
                  filters={{ cc, state }}
                  value={c}
                  onChange={(value) =>
                    onChangeHandle({ ...searchParams, c: value })
                  }
                />

                {!isEmpty(c) && (
                  <SelectCounty
                    isPlaces
                    filters={{ cc, state, c }}
                    value={county}
                    onChange={(value) =>
                      onChangeHandle({ ...searchParams, county: value })
                    }
                  />
                )}
              </>
            )}
            <Select
              style={{ minWidth: '170px' }}
              className='cursor-pointer'
              size='large'
              open={false}
              placeholder={translate('filters.moreFilters') + (advancedParametersCount > 0 ? ` (${advancedParametersCount})` : '')}
              onClick={handleFilterOpenModal}
            />
            <Space>
              <span className='main-text'>Show map</span>
              <Switch value={isMapVisible} onChange={handleMapVisible} />
            </Space>
          </Space>
          <Space className='buttons-block' size={8} align={'center'}>
            <Button
              className='search-button pl-24 pr-24'
              htmlType='submit'
              title='Search'
              type={searched ? 'default' : 'primary'}
              size='large'
              loading={loading}
              icon={<SearchOutlined />}
              onClick={onSearchHandle}
            >
              <Trans i18nKey='title.search' />
            </Button>

            <Tooltip
              title={
                searched
                  ? loadingTotal && 'Calculating results'
                  : 'Apply filters by clicking search'
              }
            >
              <ExportButton
                productName='Places'
                type={searched ? 'primary' : 'default'}
                totalResults={total}
                loading={exporting || loadingTotal}
                searchParams={searchParams}
                disabled={exportDisabled || !searched}
              />
            </Tooltip>
          </Space>
        </>
      )}
    </Form>
    <SearchStatus total={total} loading={loading} loadingTotal={loadingTotal} searched={searched} />
    <AdvancedFiltersModal
      isModalOpen={isFiltersModalOpen}
      onClose={handleFilterCloseModal}
      searchParams={searchParams} size={size}
      onChangeHandle={onChangeHandle}
      advancedParameterKeys={advancedParameterKeys}
    />
  </Space>;
}

Search.propTypes = {
  searchParams: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  exporting: PropTypes.bool,
  total: PropTypes.number,
  loadingTotal: PropTypes.bool,
  hideControls: PropTypes.bool,
  setSearched: PropTypes.func,
  isMapVisible: PropTypes.bool,
  setIsMapVisible: PropTypes.func,
  searched: PropTypes.bool,
  placesShowing: PropTypes.number
};
