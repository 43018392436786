import React from 'react';
import PropTypes from 'prop-types';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';

export default function SearchStatus({ loading, loadingTotal, total, searched }) {
  const exportDisabled = loading || loadingTotal;
  const formatedTotal = total || 0;

  return <h3 className='h3'>
    {exportDisabled ?
      <Trans i18nKey='catalog.awaitResults'>Calculating results. Please wait to download...</Trans>
      : searched ?
        total > 0 ?
          <>
            <span className='bold'>{formatedTotal.toLocaleString()}</span> <Trans i18nKey='catalog.hint1'> results found. Click download </Trans>(<DownloadOutlined />) <Trans i18nKey='catalog.hint2'>to export and choose quantity</Trans>
          </>
          :
          <Trans  i18nKey='catalog.errSearch'>
            It looks like there are no matches for your search
          </Trans>
        :
        <><Trans  i18nKey='catalog.applyNewFilters1'>Please click </Trans><strong><Trans  i18nKey='catalog.search'>search </Trans>(<SearchOutlined />)</strong> <Trans  i18nKey='catalog.applyNewFilters2'>to apply the new filters</Trans></>
    }
  </h3>;
}

SearchStatus.propTypes = {
  loading: PropTypes.bool,
  loadingTotal: PropTypes.bool,
  total: PropTypes.number,
  searched: PropTypes.bool,
};
