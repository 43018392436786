import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  border-radius: 4px;
  min-width: 150px;
  width: 100%;
  height: 31px;
`;

export default function MakePaymentButton({ loading, onMakePayment, title = 'Card', disabled = false }) {
  return (
    <StyledButton
      type='primary'
      className='make-payment'
      disabled={disabled}
      loading={loading}
      onClick={onMakePayment}
    ><CreditCardOutlined /> {title}
    </StyledButton>
  );
}

MakePaymentButton.propTypes = {
  onMakePayment: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};
