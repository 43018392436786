import React from 'react';
import PropTypes from 'prop-types';
import { Empty, Button, Space, Row, Col, Pagination } from 'antd';
import PlaceCard from './Place/PlaceCard';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { clearSelection } from '@redux/actions/selectActions';

const notFoundContent = <>
  <Empty description={<span>No results matching your filters. Please check your search terms and try again.</span>}>
    <br /><br />
    Commonly used queries:
    <br /><br />
    <a href='?cc=US&city=Palo Alto&limit=10&state=California&type=gift shop'>Gift shops in Palo Alto, USA</a><br />
    <a href='?cc=US&limit=10&state=New York&type=restaurant&type=bar'>Restaurants and bars in New York state, USA</a><br />
    <a href='?cc=US&city=san francisco&limit=10&state=california&type=real estate agency'>Real estate agencies in San Francisco, USA</a><br />
    <a href='?cc=CA&city=toronto&limit=10&type=clinic&type=doctor&type=therapists'>Clinics, doctors and therapists in Toronto, CA</a><br />
    <a href='?cc=GB&city=london&city=edinburgh&limit=10&type=gym'>Gyms in London and Edinburgh, GB</a><br />
    <br /><br /><br /><br /><br /><br />
  </Empty>
</>;

export default function PlacesCards({ places = [], total, pageSize, currentPage, onPagination, onSearchParamsChange }) {
  const selectedItems = useSelector((state) => state.items.selectedItems || []);
  const dispatch = useDispatch();

  const deselectAllHandler = () => {
    dispatch(clearSelection());
  };

  return (
    <Space className='w-100' direction='vertical'>
      <Space align='baseline'>
        <h4 className='h4'>
          <Trans i18nKey='title.selected' />: {selectedItems.length}
        </h4>
        <span className='vertical-divider'>|</span>
        <Button
          className='noPadding'
          onClick={deselectAllHandler}
          type='link'
          disabled={selectedItems.length === 0}
        >
          <Trans i18nKey='title.unselect' />
        </Button>
      </Space>
      <Space className='w-100' size={24} direction='vertical'>
        {places.length === 0 && notFoundContent}
        <Row gutter={[16, 16]}>
          {places.map((item) => (
            <Col xs={24} sm={12} xl={8} xxl={6} key={item.os_id}>
              <PlaceCard isSelected={selectedItems.includes(item.os_id)} value={item} onSearchParamsChange={onSearchParamsChange} />
            </Col>
          ))}
        </Row>
        <Pagination
          align='center'
          showSizeChanger={false}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          total={total ? total : pageSize + 1}
          onChange={onPagination}
        />
      </Space>
    </Space>
  );
}

PlacesCards.propTypes = {
  places: PropTypes.array.isRequired,
  total: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};
