import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Col, Row, Space } from 'antd';
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import { GoogleSheetsIcon } from '@assets/icons';

import { fetchIntegrations, removeIntegration, connectIntegration } from '@redux/actions/profileActions';
import { isEmpty } from 'lodash';

const { Meta } = Card;

export default function Integrations() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(state => state.profileReducer.loading);
  const integrations = useSelector(state => state.profileReducer.integrations);

  const { google = {} } = integrations;

  useEffect(() => {
    document.title = t('title.integrations');
    dispatch(fetchIntegrations());
  }, []);

  function onConnectGoogle() {
    dispatch(connectIntegration('google'));
  }

  function onRemoveIntegration(integration) {
    dispatch(removeIntegration(integration));
  }

  return (
    <Space direction='vertical' size={24} className='integrations-card'>
      <Space wrap='wrap' size={16}>
        <h2 className='h2'>{t('title.integrations')}</h2>
        <p className='subTitle'>{t('integrations.description')}</p>
      </Space>

      <Row gutter={[16, 16]} align='stretch'>
        <Col xs={24} lg={12}>
          <Card
            loading={isLoading}
            actions={[
              isEmpty(google) ? <LinkOutlined key='connect-google'
                title={t('integrations.connect')}
                onClick={onConnectGoogle} /> :
                <DeleteOutlined key='delete'
                  title={t('integrations.remove')}
                  onClick={() => onRemoveIntegration('google')} />
            ]}
          >
            <Meta
              avatar={<GoogleSheetsIcon />}
              title='Google Sheets'
              description={t('integrations.googleDescription')}
            />
          </Card>
        </Col>
      </Row>
    </Space>
  );
}
