import React, { useState } from 'react';
import { Drawer, Layout, Menu } from 'antd';
import {
  BugOutlined, CloseCircleOutlined,
  CommentOutlined, MenuOutlined,
  UserOutlined, CreditCardOutlined,
  CustomerServiceOutlined,
  DatabaseOutlined, FileTextOutlined,
  LinkOutlined, QuestionCircleOutlined,
  UploadOutlined, ApiOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import AppLogo from '@assets/app/logo.png';
import AppLogoWhite from '@assets/app/logo-white.png';
import { useTheme } from '@context/ThemeContext';
import FeatureRequestModal from '@Others/FeatureRequestModal';
import { useMedia } from '@hooks/useMedia';
import Href from '@Common/Href';
import Avatar from '@Components/Common/Avatar';
import './Navigation.scss';
import { getCompanyHomePage } from '@helpers/common';
import ThemeToggle from './ThemeToggle';

const { Header } = Layout;

const HeaderComponent = ({ auth }) => {
  const { profile = {}, authenticated = false } = auth;
  const { email, avatar } = profile;
  const { isDesktop } = useMedia();
  const { t, i18n } = useTranslation();
  const { isDarkMode } = useTheme();

  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  // const chatraElement = document.getElementById('chatra');
  // const header = chatraElement.querySelector('.header ');
  //
  // console.log('chatraElement', chatraElement);

  const translateServicesData = (data) => {
    return data.map(item => {
      const translatedLabel = t(`title.${item.label}`) || item.label;
      return {
        ...item,
        label: translatedLabel,
        children: item.children ? translateServicesData(item.children) : undefined,
      };
    });
  };

  function showUserName(email) {
    if (email) {
      return email.split('@')[0];
    } else {
      return email;
    }
  }

  function onHelpClick() {
    if (isHelpOpened) {
      window.Chatra('closeChat');
    } else {
      window.Chatra('setChatHeight', 400);
      window.Chatra('updateIntegrationData', { email });
      window.Chatra('openChat', true);
    }

    setIsHelpOpened(!isHelpOpened);
  }

  function appendLanguageToUrl(url) {
    const ln = (i18n.language || 'en').split('-')[0];

    if (ln !== 'en') {
      return `${url}${url.includes('?') ? '&' : '?'}ln=${ln}`;
    }

    return url;
  }

  const handleMenuItemClick = () => {
    setIsBurgerMenuOpen(false);
  };

  const helpMenuItems = [
    {
      title: <Trans i18nKey='sider.report' />,
      key: 'feature-request',
      icon: <BugOutlined />,
      onClick: () => setFeatureRequestOpen(prev => !prev)
    },
    {
      title: <Trans i18nKey='sider.support' />,
      key: 'support',
      icon: isHelpOpened ? (<CloseCircleOutlined />) : (<CustomerServiceOutlined />),
      onClick: onHelpClick
    },
    {
      title: <Trans i18nKey='sider.contact' />,
      key: 'contact-us',
      icon:   <CommentOutlined />,
      href: `${getCompanyHomePage()}/contact-us`
    },
  ];

  const rootMenuItems = [
    { key: '/local-businesses', title: <Trans i18nKey='sider.business' />, icon: <DatabaseOutlined /> },
    { key: '/exports', title: <Trans i18nKey='title.exports' />, icon: <UploadOutlined /> },
    { key: '/billing-info', title: <Trans i18nKey='sider.billing' />, icon: <CreditCardOutlined /> },
    { key: '/invoices', title: <Trans i18nKey='title.invoices' />, icon: <FileTextOutlined /> },
    { key: '/api-docs', title: <Trans i18nKey='title.apiDocs' /> , icon: <ApiOutlined /> },
    { key: '/integrations', title: <Trans i18nKey='title.integrations' />, icon: <LinkOutlined /> },
    {
      key: 'help',
      title: <Trans i18nKey='sider.help' />,
      icon: <QuestionCircleOutlined />,
      children: helpMenuItems,
    },
  ];

  const renderMenuItem = (item) =>
    item.children ? (
      <Menu.SubMenu key={item.key} title={item.title} icon={!isDesktop && item.icon}>
        {item.children.map((subItem) => (
          <Menu.Item key={subItem.key} onClick={subItem.onClick} icon={subItem.icon}>
            {subItem.href ? <Href external href={subItem.href}>{subItem.title}</Href> : subItem.title}
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    ) : (
      <Menu.Item key={item.key} icon={!isDesktop && item.icon} onClick={handleMenuItemClick}>
        <Href href={appendLanguageToUrl(item.key)}>{item.title}</Href>
      </Menu.Item>
    );

  const accountMenu = () =>
    <Href key='profile' href={appendLanguageToUrl('/profile')}>
      <span className='profile'>
        {authenticated ?
          <>
            <Avatar className='profile-avatar' src={avatar} email={email} size={40} />
            <h4 className='text'>{showUserName(email)}</h4>
          </> :
          <>
            <UserOutlined />
            <h4 className='text'><Trans i18nKey='title.profile'>Profile</Trans></h4>
          </>
        }
      </span>
    </Href>;

  return (
    <Header className='header'>
      <Link to='/' className='logo'>
        <img src={isDarkMode ? AppLogoWhite : AppLogo} alt='targetron logo' />
      </Link>

      {isDesktop ?
        <Menu selectedKeys={[]} mode='horizontal'>
          {rootMenuItems.map(renderMenuItem)}
        </Menu>
        :
        <>
          <MenuOutlined className='burger-menu' onClick={() => setIsBurgerMenuOpen(true)} />
          <Drawer
            title={<h4 className='h4 bold'>Menu</h4>}
            placement='right'
            onClose={() => setIsBurgerMenuOpen(false)}
            open={isBurgerMenuOpen}
          >
            <Menu className='main-menu' mode='inline' selectedKeys={[]}>
              <Menu.Item>
                {accountMenu()}
              </Menu.Item>
              {rootMenuItems.map(renderMenuItem)}
              <Menu.Item key='theme-toggle' onClick={(e) => e.domEvent.stopPropagation()}>
                <ThemeToggle />
              </Menu.Item>
            </Menu>
          </Drawer>
        </>
      }
      {isDesktop && <ThemeToggle />}
      {isDesktop && accountMenu()}
      <FeatureRequestModal bug open={featureRequestOpen} onChange={setFeatureRequestOpen} />
    </Header>
  );
};

HeaderComponent.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default HeaderComponent;
