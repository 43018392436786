import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, notification, Tooltip, Space } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Mixpanel from '@analytics/mixpanel';
import { useTranslation, Trans } from 'react-i18next';

import apiProfile from '@api/apiProfile';
import { useMedia } from '@hooks/useMedia';

const { Text } = Typography;

const CopyIcon = styled(CopyOutlined)`
    cursor: pointer;
    color: #1890ff;
    &:hover {
        color: #40a9ff;
    }
`;

export default function EmailAddress({ value, verified }) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const { isDesktop } = useMedia();

  async function onRequest() {
    setLoading(true);

    try {
      await apiProfile.requestEmailVerification();
      notification.success({ message: t('profile.checkInbox') });
      setSent(true);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }

    Mixpanel.track('Request email confirmation');
  }

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(value).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 5000);
    });
  };

  const toolTip = copied ? t('title.copiedToClipboard') : t('title.copyToClipboard');

  return (
    <Space align={isDesktop ? 'baseline' : 'unset'} direction='vertical' className='w-100'>
      <Space size={4}>
        <Tooltip title={toolTip}>
          <span className='cursor-pointer' onClick={handleCopyEmail}>
            {value}
          </span>
        </Tooltip>
        <Tooltip title={toolTip}>
          <CopyIcon onClick={handleCopyEmail} />
        </Tooltip>
        {verified ?
          <Text type='success'><Trans i18nKey='title.verified' /></Text>
          :
          <>
            <Text type='warning'><Trans i18nKey='title.unverified' /></Text>
          </>
        }
      </Space>
      {!verified && !sent && <Button className='w-100' loading={loading} onClick={onRequest}>
        <Trans i18nKey='profile.requestEmailConfirmation' />
      </Button>}
    </Space>
  );
}

EmailAddress.propTypes = {
  value: PropTypes.string.isRequired,
  verified: PropTypes.bool,
};
