import React from 'react';
import PropTypes from 'prop-types';
import { Result, Modal, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Mixpanel from '@analytics/mixpanel';
import GA from '@analytics/ga';

export default function AuthModal({ visible, onClose }) {
  function onLogin() {
    Mixpanel.track('Login (from auth modal)');
    GA.event('login');

    const { pathname, search = '' } = window.location;
    window.location = `/login?redirect=${encodeURIComponent(pathname + search)}`;
  }

  function onSignup() {
    Mixpanel.track('Signup (from auth modal)');
    GA.event('sign_up');
    GA.reportSignup();

    const { pathname, search = '' } = window.location;
    window.location = `/signup?redirect=${encodeURIComponent(pathname + search)}`;
  }

  return (
    <Modal
      destroyOnClose
      closable={false}
      title='Login to Your Account'
      open={visible}
      okButtonProps={{ icon: <UserOutlined /> }}
      onCancel={onClose}
      footer={[
        <Button ghost key='login' type='primary' icon={<UserOutlined />} onClick={onLogin}>Login</Button>,
        <Button key='signup' type='primary' icon={<UserOutlined />} onClick={onSignup}>Sign up</Button>,
      ]}
    ><Result status='403' subTitle='Please login to your account or create an account to continue' /></Modal>
  );
}

AuthModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
