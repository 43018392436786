import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification, Card, Space, Spin } from 'antd';
import qs from 'query-string';
import { saveAs } from 'file-saver';
import { cloneDeep } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { removeEmptyValues } from '@helpers/common';
import apiPlaces from '@api/apiPlaces';
import AuthModal from '@Common/AuthModal';
import Search from './Search/Search';
import SearchResults from './SearchResults';
import Map from './Map/Map';
import { clearSelection } from '@redux/actions/selectActions';
import './Places.scss';

const PAGE_SIZE = 12;
const defaultParams = {};

export default function Places({ auth }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState({ ...defaultParams, ...qs.parse(location.search), limit: PAGE_SIZE });
  const { geoFilters, view, utm_source } = searchParams;

  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingEstimate, setLoadingEstimate] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [places, setPlaces] = useState([]);
  const [total, setTotal] = useState(null);
  const [isMapVisible , setIsMapVisible] = useState(false);
  const [searched, setSearched] = useState(true);
  const skip = parseInt(searchParams.skip) || 0;
  const pageSize = parseInt(searchParams.limit) || 0;

  if (utm_source) {
    localStorage.setItem('source', utm_source);
    setSearchParams(prev => {
      const newParams = { ...prev };
      delete newParams.utm_source;
      return newParams;
    });
  }

  const { authenticated, profile = {} } = auth;
  // eslint-disable-next-line no-unused-vars
  const { emailVerified } = profile;
  const hideControls = view === 'true';

  useEffect(() => {
    document.title = 'Catalog of Local Businesses';
    fetchData(searchParams, true);
    onSearchParamsChange(searchParams);
  }, []);

  async function fetchData(params, fetchEstimate = true) {
    setLoading(true);
    try {
      const data = await apiPlaces.fetchPlaces(params);
      setPlaces(data.data);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoading(false);

    if (fetchEstimate) await fetchDataEstimate(params, { geoFilters });
  }

  async function fetchDataEstimate(params) {
    setLoadingEstimate(true);
    try {
      const data = await apiPlaces.fetchPlacesEstimate(params);
      setTotal(data.total);
    } catch (error) {
      notification.error({ message: error.message });
    }
    setLoadingEstimate(false);
  }

  async function onExport(resultType, limit) {
    setExporting(true);
    try {
      const data = await apiPlaces.exportPlaces({ ...searchParams, resultType, limit });
      saveAs(data, `places_${limit}.${resultType}`);
    } catch(error) {
      notification.error({ message: error.message });
    }
    setExporting(false);
  }

  function onSearchParamsChange(params) {
    const newParams = cloneDeep(params);
    removeEmptyValues(newParams);
    setSearchParams(searchParams => ({ ...searchParams, ...newParams }));

    if (!newParams.geoFilters) {
      navigate({ search: `?${qs.stringify(newParams)}` });
    } else {
      const shortParams = cloneDeep(newParams);
      delete shortParams.geoFilters;
      navigate({ search: `?${qs.stringify(shortParams)}` });
    }
  }

  async function onSearch(params) {
    const newParams = { ...params };
    if ('skip' in newParams) delete newParams.skip; // remove skip when change params

    onSearchParamsChange(newParams);
    dispatch(clearSelection());

    window.scrollTo({ top: 0, behavior: 'smooth' });

    await fetchData(params);
  }

  async function onPagination(currentPage) {
    const newSkip = (currentPage - 1) * pageSize;
    const { skip, ...restParams } = { ...searchParams, skip: newSkip };
    const cleanedParams = newSkip === 0 ? restParams : { ...searchParams, skip: newSkip };

    if (newSkip > 90 && !authenticated && !authModalVisible) {
      setAuthModalVisible(true);
      return;
    }

    onSearchParamsChange(cleanedParams);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    await fetchData(cleanedParams, false);
  }

  function handleSearchParamsChange(value) {
    onSearch({ ...searchParams, ...value });
  }

  return <Space direction='vertical' size={0} className='places'>
    <Card className='search-card'>
      <Search
        searched={searched}
        setSearched={setSearched}
        isMapVisible={isMapVisible}
        setIsMapVisible={setIsMapVisible}
        loading={loading}
        placesShowing={places ? places.length : 0}
        exporting={exporting}
        loadingTotal={loadingEstimate}
        searchParams={searchParams}
        total={total}
        onExport={onExport}
        onSearch={onSearch}
        onChange={onSearchParamsChange}
        hideControls={hideControls}
      />
    </Card>
    <Spin spinning={loading}>
      <Space className='w-100 content' direction='vertical'>
        {isMapVisible && <Map
          description={`Showing results ${skip + 1}-${skip + pageSize}&nbsp;&nbsp;&nbsp;&nbsp;`}
          places={places}
          searchParams={searchParams}
          geoFilters={geoFilters}
          setSearchParams={setSearchParams}
        />}
        <SearchResults
          exporting={exporting}
          places={places}
          total={total}
          skip={skip}
          pageSize={pageSize}
          onPagination={onPagination}
          onExport={onExport}
          searchParams={searchParams}
          onSearchParamsChange={handleSearchParamsChange}
        />
      </Space>
    </Spin>
    <AuthModal visible={authModalVisible} onClose={() => setAuthModalVisible(false)} />
  </Space>;
}

Places.propTypes = {
  auth: PropTypes.object.isRequired,
  authForwarded: PropTypes.object,
};
