import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import { Star } from '@assets/icons';

export default function Rating({ value }) {
  const { rating, reviews } = value;

  if (!rating) {
    return null;
  }

  return (
    <Space size={4} align='center' className='rating'>
      <Space size={2} align='center'>
        <Star />
        <span className='main-text bold'>{rating + ' '}</span>
      </Space>
      {reviews && <span className='subTitle'>({reviews} reviews)</span>}
    </Space>
  );
}

Rating.propTypes = {
  value: PropTypes.object.isRequired,
};
