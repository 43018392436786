export function handleOnCopy(e) {
  const { target } = e;
  if (target.localName === 'h4' || target.localName === 'span') {
    e.preventDefault();
    return false;
  }

  return true;
}

export function toArray(value) {
  if (!value) return [];
  return typeof value === 'string' ? [value] : value;
}

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function arraysAreEqual(a, b) {
  if (a === b) return true;
  if (!a || !b) return false;
  if (a.length !== b.length) return false;

  return a.every((el, i) => el === b[i]);
}

export const dynamicallyCreateAnchorAndNavigate = (url) => {
  let anchorElement = document.createElement('a');
  anchorElement.href = url;
  anchorElement.target = '_blank';
  anchorElement.rel = 'noopener noreferrer';
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
};

export const capitalizeFirstLetter = (str) => {
  if (!str) return str;
  return str
    .split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};
