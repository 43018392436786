import React from 'react';
import PropTypes from 'prop-types';

import { arraysAreEqual } from '@utils/helper';
import PlacesCards from './PlacesCards';

function SearchResults({ places, total, skip, pageSize, onPagination, onSearchParamsChange }) {
  const currentPage = Math.ceil((parseInt(skip | 0) + 1) / pageSize);
  return (
    <PlacesCards
      places={places}
      total={total}
      currentPage={currentPage}
      pageSize={pageSize}
      onPagination={onPagination}
      onSearchParamsChange={onSearchParamsChange}
    />
  );
}

SearchResults.propTypes = {
  places: PropTypes.array.isRequired,
  total: PropTypes.number,
  skip: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPagination: PropTypes.func.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
};

export default React.memo(SearchResults, (prevProps, nextProps) => {
  return (
    prevProps.total === nextProps.total &&
    prevProps.skip === nextProps.skip &&
    prevProps.pageSize === nextProps.pageSize &&
    arraysAreEqual(prevProps.places, nextProps.places)
  );
});
