import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Tooltip, Space, Button, Form, message, notification } from 'antd';
import { VscSparkleFilled } from 'react-icons/vsc';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getLanguage, checkIfPaidUser, displayWords } from '@utils/utils';
import Mixpanel from '@analytics/mixpanel.js';
import HelperAIModal from './HelperAIModal';
import { toArray, toTitleCase } from '@utils/helper';
import GMBtypes from '@shared/data/types.json';
import apiProfile from '@api/apiProfile.js';

const { Option } = Select;

const MAX_TAG_COUNT = 1;

const StyledButton = styled.button`
  padding-left: 6px;
  text-decoration: underline;
  padding-right: 1px;
`;

const buttonAIStyles = {
  position: 'absolute',
  right: '20px',
  top: '50%',
  transform: 'translateY(-50%)',
  height: 'auto',
  zIndex: 10,
  padding: 0,
};

export default function SelectType({ value, onChange, size, loading, types, placeholder = 'Category', showQucikButtons, showAI = true }) {
  const arrayValue = toArray(value);
  const selectedLength = arrayValue.length;
  const title = selectedLength > MAX_TAG_COUNT ? arrayValue.join(', ') : null;
  const isMaxValues = selectedLength > 100;
  const availableTypes = types || GMBtypes;
  const isPaidUser = checkIfPaidUser();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingAI, setLoadingAI] = useState(false);
  const [form] = Form.useForm();

  function showModal() {
    setIsModalOpen(true);
  }

  function handleShowError() {
    message.error('The maximum amount of options selected');
  }

  function handleCancel() {
    if (loadingAI) {
      notification.warning({
        message: 'Please wait while the request is being processed',
        description: 'Please do not close the modal until it is finished.',
      });
    } else {
      setIsModalOpen(false);
    }
  }

  async function handleSubmit(values) {
    setLoadingAI(true);
    try {
      Mixpanel.track('AI categories selection', { text: values.user_input });
      const { categories, description } = await apiProfile.getCategories({ ...values, language: getLanguage() });

      if (categories && categories.length > 0) {
        onChange(categories.filter(category => availableTypes.includes(category)));
        form.resetFields();

        notification.success({
          message: t('title.selected') + ': ' + displayWords(categories, 2, '0'),
          description: description,
          duration: 15,
        });
      } else {
        notification.error({ message: t('errors.notFound') });
      }
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setIsModalOpen(false);
      setLoadingAI(false);
    }
  }

  return (
    <div>
      <Tooltip
        placement='right'
        mouseEnterDelay={0.3}
        title={title}
      >
        <Space className='first-space-item-full positionRelative' direction='horizontal' size={0}>
          <Select
            allowClear
            autoFocus
            showSearch
            autoClearSearchValue={false}
            maxTagCount={MAX_TAG_COUNT}
            mode='multiple'
            tokenSeparators={[',', '\n', '\t']}
            onSelect={(value, option) => {
              if (isMaxValues) {
                handleShowError();
                return;
              }
            }}
            onPaste={(e) => {
              e.preventDefault();
              const pastedText = e.clipboardData.getData('text');
              const values = pastedText.split(/[,\n\t\s]+/).filter(Boolean);
              const validValues = values.filter(v => availableTypes.includes(v.toLowerCase()));

              if (validValues.length < values.length) {
                message.info(`${values.length - validValues.length} categories were skipped as they don't exist in the available options`);
              }

              if (validValues.length) {
                const newValues = [...new Set([...toArray(value), ...validValues])];
                if (newValues.length > 100) {
                  handleShowError();
                  return;
                }
                onChange(newValues);
              }
            }}
            style={{ minWidth: '170px' }}
            loading={loading}
            size={size}
            placeholder={t('filters.category')}
            value={value}
            onChange={onChange}
            optionFilterProp='children'
            {...(isMaxValues && { open: false, onDropdownVisibleChange: handleShowError })}
          >
            {availableTypes.map((r) => (
              <Option key={r} value={r}>{toTitleCase(r)}</Option>
            ))}
          </Select>
          {!value?.length && isPaidUser && showAI && (
            <Button
              type='link'
              icon={<VscSparkleFilled />}
              style={buttonAIStyles}
              onClick={showModal}
            />
          )}
          <HelperAIModal
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            form={form}
            handleSubmit={handleSubmit}
            loading={loadingAI}
          />
        </Space>
      </Tooltip>

      {showQucikButtons && <div>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(GMBtypes.slice(0, 100))}
        >
          Top 100,
        </StyledButton>
        <StyledButton
          type='button'
          className='link-button'
          onClick={() => onChange(GMBtypes.slice(0, 200))}
        >
          200
        </StyledButton>
      </div>}
    </div>
  );
}

SelectType.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  types: PropTypes.array,
  placeholder: PropTypes.string,
  showQucikButtons: PropTypes.bool,
  showAI: PropTypes.bool,
};
