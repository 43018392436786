import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Space } from 'antd';

import ProfileHeader from './ProfileHeader';
import AppLogo from '@assets/app/logo.png';
import AppLogoWhite from '@assets/app/logo-white.png';
import { useTheme } from '@context/ThemeContext';
import './Navigation.scss';
import { Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';

export default function BusinessesHeader({ auth }) {
  const queryParams = new URLSearchParams(window.location.search);

  const shouldHideHeader = () => queryParams.has('hideHeader');
  const shouldHideLogo = () => queryParams.has('hideLogo');

  const { isDarkMode } = useTheme();

  return (
    <>
      {!shouldHideHeader() &&
        <Row className='businesses-header justifySpaceBetween alignCenter'>
          <Col>
            {!shouldHideLogo() && <Link to='/' className='logo'>
              <img src={isDarkMode ? AppLogoWhite : AppLogo} alt='targetron logo' />
            </Link>}
          </Col>
          <Col>
            <Space size={10} className='alignCenter'>
              <ThemeToggle />
              <ProfileHeader auth={auth} />
            </Space>
          </Col>
        </Row>}
    </>
  );
}

BusinessesHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};
