import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from '@locales/de/translation.json';
import es from '@locales/es/translation.json';
import fr from '@locales/fr/translation.json';
import it from '@locales/it/translation.json';
import br from '@locales/br/translation.json';
import ru from '@locales/ru/translation.json';
import tr from '@locales/tr/translation.json';
import uk from '@locales/uk/translation.json';
import zh from '@locales/zh/translation.json';
import en from '@locales/en/translation.json';
import ar from '@locales/ar/translation.json';
import hi from '@locales/hi/translation.json';
import jp from '@locales/jp/translation.json';
import ko from '@locales/ko/translation.json';
import pl from '@locales/pl/translation.json';
import th from '@locales/th/translation.json';
import vi from '@locales/vi/translation.json';

const resources = {
  en: { translation: en },
  de: { translation: de },
  es: { translation: es },
  fr: { translation: fr },
  it: { translation: it },
  br: { translation: br },
  ru: { translation: ru },
  tr: { translation: tr },
  uk: { translation: uk },
  zh: { translation: zh },
  ar: { translation: ar },
  hi: { translation: hi },
  jp: { translation: jp },
  ko: { translation: ko },
  pl: { translation: pl },
  th: { translation: th },
  vi: { translation: vi },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
