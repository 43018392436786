import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Space } from 'antd';

import { handleOnCopy } from '@utils/helper';
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  CheckCircle,
  TickTokIcon,
  TwitterIcon
} from '@assets/icons';

const FULL_DATA_TIP = 'Click the "Export" button to get this data.';

export default function PlaceTitle({ value, onExport }) {
  const { display_name, verified, owner_title, linkedin, facebook, twitter, instagram, ticktok, } = value;

  function onCopy(e) {
    if (!handleOnCopy(e) && onExport) {
      onExport();
    }
  }

  return (
    <div className='place-title' onCopy={onCopy}>
      <div className='title-check'>
        {verified &&
          <Tooltip title={`Claimed by owner (${owner_title})`}>
            <div className='icon-container'>
              <CheckCircle />
            </div>
          </Tooltip>
        }
        <Tooltip title={display_name}>
          <h2 className='h2 title'>
            {display_name}
          </h2>
        </Tooltip>
      </div>
      <Tooltip title={FULL_DATA_TIP} className='socials'>
        <Space size={4}>
          {linkedin && <LinkedInIcon />} {facebook && <FacebookIcon />} {twitter &&
                <TwitterIcon />} {instagram && <InstagramIcon />} {ticktok &&
                  <TickTokIcon />}
        </Space>
      </Tooltip>
    </div>
  );
}

PlaceTitle.propTypes = {
  value: PropTypes.object.isRequired,
  onExport: PropTypes.func,
};
