import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, notification } from 'antd';

import apiProfile from '@api/apiProfile';

const { TextArea } = Input;

export default function FeatureRequestModal({ open, onChange }) {
  const [text, setText] = useState('');

  async function onSend() {
    onClose();

    try {
      const data = await apiProfile.featureRequest(text);
      notification.success({ message: data });
    } catch (error) {
      notification.error({ message: error.message });
    }
    setText('');
  }

  function onClose() {
    onChange(false);
  }

  return (
    <Modal
      okText='Send'
      title='Report a Problem'
      open={open}
      onOk={onSend}
      onCancel={onClose}
    >
      <TextArea
        autoFocus
        rows={4}
        placeholder='What struggles do you have?'
        value={text}
        onChange={({ target }) => setText(target.value)}
      />
    </Modal>
  );
}

FeatureRequestModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};
