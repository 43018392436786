import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { List, Button, Modal, Form, Input, Space, Typography, Popconfirm, Tooltip } from 'antd';
import Avatar from '@Common/Avatar';

import { useMedia } from '@hooks/useMedia';
import { checkIfPaidUser } from '@utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { removeTenant, inviteTenant } from '@redux/actions/profileActions';
import PremiumFeatureTooltip from '@shared/Components/PremiumFeatureTooltip';

export default function Tenants({ tenants = [] }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isDesktop } = useMedia();
  const paidUser = checkIfPaidUser();
  const [modalVisible, setModalVisible] = useState(false);
  const loading = useSelector(state => state.profileReducer.loading);

  function onInvite({ email }) {
    dispatch(inviteTenant(email));
    setModalVisible(false);
    form.resetFields();
  }

  function onRemoveTenant(tenantUserId) {
    dispatch(removeTenant(tenantUserId));
  }

  return (
    <>
      {tenants.length > 0 && (
        <List
          itemLayout='horizontal'
          dataSource={tenants}
          className='w-30'
          renderItem={(tenant, index) => (
            <List.Item
              key={`${tenant.id}-${index}`}
              actions={[
                <Tooltip title='Remove Tenant' key='remove'>
                  <Popconfirm
                    title='Are you sure?'
                    okText='Yes'
                    cancelText='No'
                    onConfirm={() => onRemoveTenant(tenant.id)}
                  >
                    <Button
                      size='small'
                      type='link'
                      icon={<DeleteOutlined />}
                      loading={loading}
                      danger />
                  </Popconfirm>
                </Tooltip>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    email={tenant.email}
                    size='large'
                  />
                }
                title={<Typography.Text>{tenant.email}</Typography.Text>}
                description='Member'
              />
            </List.Item>
          )}
        />
      )}

      {tenants.length < 10 && (
        <PremiumFeatureTooltip isPaidUser={paidUser}>
          <Tooltip title='Invite a new team member'>
            <Button
              type='link'
              icon={<UserAddOutlined />}
              onClick={() => setModalVisible(true)}
              className='noPadding'
              disabled={!paidUser}
              loading={loading}
            >
              Invite
            </Button>
          </Tooltip>
        </PremiumFeatureTooltip>
      )}

      <Modal
        width={isDesktop ? '460px' : '100%'}
        destroyOnClose
        footer={null}
        title='Invite Team Member'
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
      >
        <Typography.Paragraph>
          The invited team members will be able to export data and view/create/remove API keys.
        </Typography.Paragraph>
        <Form form={form} name='add-tenant' layout='vertical' onFinish={onInvite}>
          <Space direction='vertical' size={16} className='w-100'>
            <Form.Item
              name='email'
              label='Email'
              rules={[{ type: 'email', required: true, message: 'Please enter a valid email' }]}
              className='noMargin'
            >
              <Input placeholder='Enter email address' />
            </Form.Item>
            <Form.Item className='noMargin'>
              <Button className='w-100' type='primary' htmlType='submit' loading={loading}>
                Send Invitation
              </Button>
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
}

Tenants.propTypes = {
  tenants: PropTypes.array.isRequired,
};
