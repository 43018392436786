import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { ReactCountryFlag } from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { regions } from '@shared/data/regions';

const { Option } = Select;

export default function SelectCountry({ value, onChange }) {
  const { t } = useTranslation();
  return (
    <Select
      allowClear
      showSearch
      placeholder={t('title.country')}
      style={{ minWidth: '170px' }}
      size='large'
      value={value}
      onChange={onChange}
      optionFilterProp='key'
    >
      {regions.map(r => <Option key={`${r.name}-${r.value}-${r.alpha3}`} value={r.value}><ReactCountryFlag svg countryCode={r.value} /> {r.name}</Option>)}
    </Select>
  );
}

SelectCountry.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
