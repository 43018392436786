import ReactGA4 from 'react-ga4';
import config from 'react-global-configuration';

// eslint-disable-next-line no-undef
let prodEnv = process.env.NODE_ENV === 'production';

const AW_BEGIN_CHECKOUT = config.get('gaBCToken');
const AW_PURCHASE= config.get('gaPCToken');
const AW_SIGNUP = config.get('gaLIToken');

const actions = {
  init: () => {
    try {
      if (prodEnv) {
        ReactGA4.initialize(config.get('gaToken'));
        if (window.gtag) {
          window.gtag('config', 'AW-11535867687');
        }
      }

      window.dataLayer = window.dataLayer || [];
    } catch (error) {
      console.error('GA error:', error);
    }
  },

  event: (eventName, params = {}) => {
    try {
      if (!prodEnv) {
        console.log('GA Event:', eventName, params);
      }

      const eventParams = {
        ...params,
        currency: params.currency || 'USD',
        value: params.value || 1,
        items: params.items || []
      };

      if (window.gtag) {
        window.gtag('event', eventName, eventParams);
      }
    } catch(error) {
      console.error('GA error:', error);
      if (!prodEnv) {
        console.error('Full error details:', error);
      }
    }
  },

  reportBeginCheckout: (url) => {
    try {
      if (!prodEnv) {
        console.log('GA Conversion:', url);
      }

      if (window.gtag) {
        const callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };

        window.gtag('event', 'conversion', {
          'send_to': AW_BEGIN_CHECKOUT,
          'value': 1.0,
          'currency': 'USD',
          'event_callback': callback
        });
      }

      return false;
    } catch(error) {
      console.error('GA conversion error:', error);
      if (!prodEnv) {
        console.error('Full error details:', error);
      }
      return false;
    }
  },

  reportPurchase: (transactionId, value = 1.0) => {
    try {
      if (!prodEnv) {
        console.log('GA Purchase Conversion:', transactionId, value);
      }

      if (window.gtag) {
        window.gtag('event', 'conversion', {
          'send_to': AW_PURCHASE,
          'value': value,
          'currency': 'USD',
          'transaction_id': transactionId || ''
        });
      }
    } catch(error) {
      console.error('GA purchase conversion error:', error);
      if (!prodEnv) {
        console.error('Full error details:', error);
      }
    }
  },

  reportSignup: (url) => {
    try {
      if (!prodEnv) {
        console.log('GA Signup Conversion:', url);
      }

      if (window.gtag) {
        const callback = function () {
          if (typeof(url) != 'undefined') {
            window.location = url;
          }
        };

        window.gtag('event', 'conversion', {
          'send_to': AW_SIGNUP,
          'event_callback': callback
        });
      }
      return false;
    } catch(error) {
      console.error('GA signup conversion error:', error);
      if (!prodEnv) {
        console.error('Full error details:', error);
      }
      return false;
    }
  }
};

export default actions;
