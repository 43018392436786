import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

export const MapEffect = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    if (map) {
      map.setView(center, zoom);
    }
  }, [center]);

  return null;
};

MapEffect.propTypes = {
  center: PropTypes.array.isRequired,
  zoom: PropTypes.number.isRequired,
};
