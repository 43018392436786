import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

export default function PlaceTypes({ types }) {
  const visibleTags = types.slice(0, 2);
  const hiddenTags = types.slice(2);

  return (
    <div className='bottom-block'>
      {visibleTags.map((type) => (
        <span key={type} className='tag'>{type}</span>
      ))}
      {hiddenTags.length > 0 && (
        <Tooltip title={hiddenTags.join(', ')}>
          <span className='tag'>+{hiddenTags.length} more</span>
        </Tooltip>
      )}
    </div>
  );
}

PlaceTypes.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};
