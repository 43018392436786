import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { Button } from 'antd';
import Mixpanel from '@analytics/mixpanel';
import GA from '@analytics/ga';

const StyledButton = styled(Button)`
  border-radius: 4px;
  min-width: 150px;
  width: 100%;
  height: 31px;
`;

export default function MakePaymentWithCryptoButton({ amount, loading, callback, secondaryTab }) {
  const navigate = useNavigate();
  function onClick() {
    GA.event('begin_checkout', {
      currency: 'USD',
      value: amount,
      payment_type: 'crypto',
      items: []
    });
    GA.reportBeginCheckout();
    Mixpanel.track('Payment intent', { amount, method: 'Crypto' });

    if (callback) {
      callback();
    }

    const link = `/profile/crypto-payment?amount=${amount}`;
    if (secondaryTab) {
      window.open(link, '_blank', 'noopener,noreferrer');
    } else {
      navigate(link);
    }
  }

  return (
    <StyledButton
      type='primary'
      loading={loading}
      onClick={onClick}
    ><Trans i18nKey='title.cryptocurrency'>Cryptocurrency</Trans></StyledButton>
  );
}

MakePaymentWithCryptoButton.propTypes = {
  amount: PropTypes.number,
  loading: PropTypes.bool,
  callback: PropTypes.func,
  secondaryTab: PropTypes.bool,
};
