import React, { useEffect, useState } from 'react';
import { Table, Button, notification } from 'antd';
import { ReactCountryFlag } from 'react-country-flag';
import Moment from 'react-moment';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { toArray } from '@utils/helper';
import apiProfile from '@api/apiProfile';
import Categories from '@Common/Categories';
import Address from '@Common/Address';

export default function CheckoutHistory() {
  const location = useLocation();
  const { search } = location;
  const { type = 'places', btn = 'See Data' } = qs.parse(search);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const tasksColumns = [
    {
      title: t('title.what'),
      dataIndex: 'id',
      key: 'id',
      render: (id, { search_params }) => <>
        <Categories value={search_params.type ? toArray(search_params.type) : ['all categories']} />
      </>
    },
    {
      title: t('title.where'),
      dataIndex: 'id',
      key: 'id',
      render: (id, { search_params }) => <>
        <div title={search_params.cc}>
          <ReactCountryFlag countryCode={search_params.cc} /> <Address value={search_params} />
        </div>
      </>
    },
    {
      title: t('title.when'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => <>{created_at && <Moment utc local fromNow>{created_at}</Moment>}</>,
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      render: (id, { search_params, product_name, total }) =>
        <Button
          size='small'
          title={`About ${total} rows`}
          onClick={() => onOpen(search_params, product_name)}
        >
          {btn}
        </Button>
      ,
    }
  ];

  useEffect(() => {
    onFetchData();
  }, []);

  function onOpen(searchParams, productName) {
    const productUrl = productName === 'places' ? 'local-businesses' : 'businesses';
    window.open(`/${productUrl}?${qs.stringify(searchParams)}`, '_blank').focus();
  }

  async function onFetchData() {
    setLoading(true);
    try {
      const data = await apiProfile.getCheckoutHistory(type);
      setData(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  return <>
    <Table
      rowKey={record => `${record.created_at}${record.product_name}`}
      size='small'
      scroll={{ y: 800 }}
      loading={loading}
      pagination={false}
      columns={tasksColumns}
      dataSource={data}
    />
  </>;
}
