import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Input, Button, notification, Space } from 'antd';

import { isValidEmail } from '@utils/utils';
import { updateGodMode } from '@redux/actions/profileActions';
import { useMedia } from '@hooks/useMedia';

export default function SupportBlock({ profile, loading, auth }) {
  const { ie } = profile;
  const [investigationEmail, setInvestigationEmail] = useState(ie);
  const investigationEmailNotValid = investigationEmail && !isValidEmail(investigationEmail);
  const dispatch = useDispatch();
  const { isDesktop } = useMedia();
  const isTurnOff = ie === investigationEmail || !investigationEmail;

  async function onUpdateGodMode(email) {
    try {
      dispatch(updateGodMode(email, afterChangeGodMode));
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  async function afterChangeGodMode() {
    await auth.renewSession();
    await new Promise(r => setTimeout(r, 2500));
    window.location.reload();
  }

  async function onTurnOnGodMode() {
    await onUpdateGodMode(investigationEmail);
  }

  async function onGodModeChange() {
    if (isTurnOff) {
      await onUpdateGodMode(null);
    } else {
      await onTurnOnGodMode();
    }
  }

  return <Space direction='vertical' size={16} className='second-space-item-full'>
    <h3 className='h3 bold'>God Mode Email</h3>
    <Space className={isDesktop ? 'w-100' : 'first-space-item-full'}>
      <Input
        disabled={loading}
        className='w-100'
        value={investigationEmail}
        onChange={({ target: { value } }) => setInvestigationEmail(value)}
      />
      {ie ? <>
        <Button
          title='Turn God Mode off'
          onClick={onGodModeChange}
          loading={loading}
          disabled={investigationEmailNotValid}
        >{isTurnOff ? 'Turn Off' : 'Change'}</Button>
      </> : <>
        <Button
          className='reset-padding'
          title='Turn God Mode on (set investigation email)'
          onClick={onTurnOnGodMode}
          loading={loading}
          disabled={investigationEmailNotValid}
        >Turn On</Button>
      </>}
    </Space>
  </Space>;
}

SupportBlock.propTypes = {
  profile: PropTypes.object,
  loading: PropTypes.bool,
  auth: PropTypes.object,
};
