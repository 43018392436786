import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Avatar from '@Common/Avatar';

export default function ProfileHeader({ auth }) {
  const { balance = 0 } = useSelector(state => state.profileReducer.profile);
  const { profile = {}, authenticated = false } = auth;
  const { email, avatar } = profile;
  const navigate = useNavigate();

  function toProfile() {
    if (!authenticated && window.self !== window.top) {
      window.open('/profile', '_blank').focus();
    } else {
      navigate('/profile');
    }
  }

  return <div>
    {authenticated ?
      <Space>
        <div className='cursor-pointer' onClick={toProfile}>
          <Avatar size={40} src={avatar} email={email} />
        </div>
        <Space direction='vertical'>
          <h3 className='h3 bold'>${(balance.toFixed(2))}</h3>
          <h3 className='h3'><Trans i18nKey='catalog.header'/></h3>
        </Space>
      </Space> :
      <Button
        type='link'
        title='Login to your account'
        icon={<UserOutlined />}
        onClick={toProfile}
      ><Trans i18nKey='title.login' /></Button>
    }
  </div>;
}

ProfileHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};
