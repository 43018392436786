import React from 'react';
import PropTypes from 'prop-types';
import { Form, InputNumber, Collapse, Space, Button } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';

import ResultsTypeSelect from './../ResultsTypeSelect';

const MIN_QUANTITY = 1;

export default function ConfirmStage({ type, quantity, skip, loading, price, totalResults = 0, setType, onSetQuantity, setSkip, setPricesOpened }) {
  const { t } = useTranslation();
  return <Form className='confirm-stage' labelCol={{ span: 4 }} wrapperCol={{ span: 14 }}>
    <Form.Item label={t('title.type')} tooltip={t('checkout.hint')}>
      <ResultsTypeSelect loading={loading} value={type} onChange={setType} />
    </Form.Item>
    <Form.Item
      label={t('title.quantity')}
      tooltip={t('checkout.quantityTooltip', {
        max: totalResults ? totalResults.toLocaleString() : t('title.undefined', 'undefined'),
      })}
    >
      <Space direction='vertical' size={0}>
        <Space>
          <InputNumber
            className='w-100'
            autoFocus
            min={MIN_QUANTITY}
            max={type === 'xlsx' ? Math.min(totalResults, 1000000) : totalResults}
            step={1000}
            value={quantity}
            disabled={loading}
            onChange={(v) => onSetQuantity(v || MIN_QUANTITY)}
          />
          {totalResults && (
            <>
              {t('checkout.from')}
              <Button
                type='link'
                className='noPadding italic underline'
                onClick={() => onSetQuantity(totalResults)}
              >
                {totalResults.toLocaleString()}
              </Button>
              {t('title.available', 'available')}
            </>
          )}
        </Space>
        {totalResults && totalResults !== quantity && (
          <Button type='link' className='noPadding italic underline' onClick={() => onSetQuantity(totalResults)}>
            <Space size={4}>
              <Trans i18nKey='title.all' />
              {totalResults.toLocaleString()}
            </Space>
          </Button>
        )}
      </Space>
    </Form.Item>
    <Collapse ghost>
      <Collapse.Panel forceRender header={t('title.options')} key='options'>
        <Form.Item label={t('title.skip')} tooltip={t('checkout.skipHint')}>
          <InputNumber
            className='w-100'
            autoFocus
            max={totalResults - 1}
            min={0}
            step={100}
            value={skip}
            disabled={loading}
            onChange={(v) => setSkip(v || 0)}
          />
        </Form.Item>
      </Collapse.Panel>
    </Collapse>
    <Space className='w-100 justifyCenter' size={4}>
      <h2 className='h2'>
        <Trans i18nKey='title.totalPrice'/>
      </h2>
      <QuestionCircleTwoTone
        title='Pricing'
        onClick={() => setPricesOpened(true)}
      />
      <span className='main-text'>
       :
      </span>
      <h2 className='h2 bold green'>${price.toFixed(2).toLocaleString()}</h2>
    </Space>

  </Form>;
}

ConfirmStage.propTypes = {
  type: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  skip: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  price: PropTypes.number.isRequired,
  totalResults: PropTypes.number,
  searchParams: PropTypes.object,
  setType: PropTypes.func.isRequired,
  onSetQuantity: PropTypes.func.isRequired,
  setSkip: PropTypes.func.isRequired,
  setPricesOpened: PropTypes.func.isRequired,
};
