import JSZip from 'jszip';
import xml2js from 'xml2js';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import {
  MapContainer,
  FeatureGroup,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  useMap,
  Polygon,
  Circle
} from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import PlaceTitle from '../Place/PlaceTitle';
import { arraysAreEqual } from '@utils/helper';
import './Map.scss';

import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { MapEffect } from './MapEffect';
let DefaultIcon = L.icon({ iconUrl: icon, shadowUrl: iconShadow });
L.Marker.prototype.options.icon = DefaultIcon;

const DEFAULT_ZOOM = 6;

function Map({ places = [], geoFilters, description, setSearchParams }) {
  const [kmzCoordinates, setKmzCoordinates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [center, setCenter] = useState(
    places.length > 0 ? [places[0].latitude, places[0].longitude] : [40.7055537, -74.0134436]
  );

  useEffect(() => {
    if (places.length > 0) {
      setCenter([places[0].latitude, places[0].longitude]);
    }
  }, [places]);

  let editableFGref = null;

  useEffect(() => {
    if (!geoFilters || geoFilters.length < 1) {
      removeAllLayers();
      setKmzCoordinates([]);
    }

  }, [geoFilters]);

  async function parseKmzFile(file) {
    const zip = await JSZip.loadAsync(file);
    const kmlFile = zip.file(/.kml$/i)[0]; // Find the KML file in KMZ
    const kmlContent = await kmlFile.async('string');

    const parser = new xml2js.Parser();
    const kmlJson = await parser.parseStringPromise(kmlContent);

    return extractCoordinatesFromKml(kmlJson);
  }

  function extractCoordinatesFromKml(kmlJson) {
    const coordinates = [];
    const folders = kmlJson.kml.Document[0].Folder || [];

    folders.forEach(folder => {
      const placemarks = folder.Placemark || [];

      placemarks.forEach((placemark, index) => {
        if (placemark.MultiGeometry && placemark.MultiGeometry[0].Polygon) {
          const polygons = placemark.MultiGeometry[0].Polygon;

          polygons.forEach(polygon => {
            const coordString = polygon.outerBoundaryIs[0].LinearRing[0].coordinates[0];
            const coords = coordString.trim().split(/\s+/).map(c => {
              const [lng, lat] = c.split(',').map(Number);
              return [lat, lng];
            });
            coordinates.push({ type: 'polygon', coords });
          });

        } else if (placemark.Point) {
          const [lng, lat] = placemark.Point[0].coordinates[0].split(',').map(Number);
          coordinates.push({ type: 'point', coords: [lat, lng] });
        } else {
          console.warn(`Unknown geometry at placemark index ${index}`, placemark);
        }
      });
    });

    return coordinates.length > 0 ? coordinates : null;
  }

  function removeAllLayers() {
    if (editableFGref) {
      const drawnItems = editableFGref._layers;

      Object.keys(drawnItems).forEach(layerid => {
        editableFGref.removeLayer(drawnItems[layerid]);
      });
    }
  }

  function onSetActive(place) {
    console.log(place);
  }

  function metersToRadian(meters){
    const earthRadiusInmeters = 6371000;
    return meters / earthRadiusInmeters;
  }

  function onFeatureGroupReady(reactFGref) {
    if (reactFGref) {
      editableFGref = reactFGref;
    }
  }

  function layerToFilter(layer) {
    let result;
    const { _mRadius, _leaflet_id, _latlng } = layer;

    if (_mRadius) {
      const { lat, lng } = _latlng;
      result = {
        type: 'Feature',
        properties: {},
        geometry: { type: 'Circle', coordinates: [lng, lat], radius: metersToRadian(_mRadius) }
      };
    } else {
      result = layer.toGeoJSON();
    }

    result.properties.leaflet_id = _leaflet_id;
    return result;
  }

  function handleCreated({ layer }) {
    const filter = layerToFilter(layer);
    setSearchParams((prevParams) => {
      const updatedGeoFilters = [...(prevParams.geoFilters || []), filter];
      return {
        ...prevParams,
        geoFilters: updatedGeoFilters,
      };
    });
  }

  function handleEdited({ layers }) {
    setSearchParams((prevParams) => {
      const currentGeoFilters = prevParams.geoFilters ? [...prevParams.geoFilters] : [];
      let isUpdated = false;

      layers.eachLayer((layer) => {
        const { _leaflet_id } = layer;
        const previousFilterId = getFilterIndexById(_leaflet_id, currentGeoFilters);

        if (previousFilterId !== -1) {
          currentGeoFilters[previousFilterId] = layerToFilter(layer);
          isUpdated = true;
        }
      });

      return isUpdated ? { ...prevParams, geoFilters: currentGeoFilters } : prevParams;
    });
  }

  function handleDeleted({ layers }) {
    setSearchParams((prevParams) => {
      const currentGeoFilters = Array.isArray(prevParams.geoFilters) ? [...prevParams.geoFilters] : [];
      const indexesToDelete = [];

      layers.eachLayer((layer) => {
        const { _leaflet_id } = layer;
        const index = getFilterIndexById(_leaflet_id, currentGeoFilters);
        if (index !== -1) {
          indexesToDelete.push(index);
        }
      });

      indexesToDelete.sort((a, b) => b - a);
      const updatedGeoFilters = currentGeoFilters.filter((_, index) => !indexesToDelete.includes(index));

      return { ...prevParams, geoFilters: updatedGeoFilters };
    });
  }

  function getFilterIndexById(leafletId, geoFilters) {
    if (!Array.isArray(geoFilters)) {
      return -1;
    }

    for (let i = 0; i < geoFilters.length; i++) {
      if (geoFilters[i].properties.leaflet_id === leafletId) {
        return i;
      }
    }

    return -1;
  }

  async function handleKmzUpload(event) {
    setLoading(true);
    const file = event.target.files[0];

    if (file) {
      const coordinates = await parseKmzFile(file);
      setKmzCoordinates(coordinates);

      const newGeoFilters = coordinates.map((shape, index) => ({
        type: 'Feature',
        properties: { leaflet_id: `kmz-${index}` },
        geometry: shape.type === 'polygon'
          ? { type: 'Polygon', coordinates: [shape.coords.map(([lat, lng]) => [lng, lat])] }
          : { type: 'Point', coordinates: [shape.coords[1], shape.coords[0]] }
      }));

      setSearchParams((prevParams) => ({ ...prevParams, geoFilters: newGeoFilters }));
      setLoading(false);
    }
  }

  function UploadControl() {
    const map = useMap();

    useEffect(() => {
      const controlDiv = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
      controlDiv.innerHTML = `
        <label style="cursor: pointer; padding: 5px; font-size: 12px;">
          📁 Upload KMZ
          <input type="file" accept=".kmz" style="display: none;" />
        </label>`;

      controlDiv.querySelector('input').addEventListener('change', handleKmzUpload);

      const control = L.control({ position: 'bottomright' });
      control.onAdd = () => controlDiv;
      control.addTo(map);

      return () => {
        control.remove();
      };
    }, [map]);
    return null;
  }

  return (<Spin spinning={loading} >
    <MapContainer className='map-container' center={center} zoom={DEFAULT_ZOOM} zoomControl={false}>
      <MapEffect center={center} zoom={DEFAULT_ZOOM} />
      <ZoomControl position='topleft'/>
      <TileLayer
        attribution={description}
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />

      {places && places.map(place =>
        <Marker
          key={place.os_id}
          position={[
            place ? place.latitude : 40.7055537,
            place ? place.longitude : -74.0134436
          ]} onClick={() => onSetActive(place)}>
          <Popup>
            <PlaceTitle key={place.id} value={place}/>
          </Popup>
        </Marker>
      )}

      {kmzCoordinates.map((shape, index) => shape.type === 'polygon' ? <Polygon key={index} positions={shape.coords} /> : <Circle key={index} center={shape.coords} radius={200} /> )}

      <FeatureGroup ref={(reactFGref) => onFeatureGroupReady(reactFGref)}>
        <EditControl
          position='topright'
          onEdited={handleEdited}
          onCreated={handleCreated}
          onDeleted={handleDeleted}
          draw={{ polyline: false, marker: false, circlemarker: false, rectangle: true }}
        />
        <UploadControl />
      </FeatureGroup>
    </MapContainer>
  </Spin>);
}

Map.propTypes = {
  places: PropTypes.array.isRequired,
  geoFilters: PropTypes.array,
  description: PropTypes.string,
  setSearchParams: PropTypes.func.isRequired,
};

export default React.memo(Map, (prevProps, nextProps) => {
  return (
    arraysAreEqual(prevProps.places, nextProps.places) &&
    arraysAreEqual(prevProps.geoFilters, nextProps.geoFilters)
  );
});
