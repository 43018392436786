import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { message, Button, Card, Form, Input, Select, Row, Col, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import { regions } from '@shared/data/regions';
import { fetchBillingInfo, updateBillingInfo, fetchProfile } from '@redux/actions/profileActions';
import { useMedia } from '@hooks/useMedia';

const { Option } = Select;

export default function BillingInformation() {
  const location = useLocation();
  const { search } = location;
  const { session_id, checkout, secondary_tab } = queryString.parse(search);

  const billingInfo = useSelector(state => state.profileReducer.billingInfo);
  const loading = useSelector(state => state.profileReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobileOrTable } = useMedia();

  const [formUpdated, setFormUpdated] = useState(false);
  const [edited, setEdited] = useState(false);
  const { address, name, description } = billingInfo;

  const [form] = Form.useForm();

  if (!formUpdated && !isEmpty(billingInfo)) {
    setFormUpdated(true);
    form.setFieldsValue(billingInfo);
  }

  useEffect(() => {
    document.title = t('billingInformation.siteTitle');

    if (checkout === 'success' && session_id) {
      if (secondary_tab && secondary_tab === 'true') {
        message.success(t('billingInformation.message1'), 30);
      } else {
        message.success(t('billingInformation.message2'));
      }

      fetchData();
    } else {
      fetchData();
    }
  }, []);

  function fetchData() {
    dispatch(fetchBillingInfo());
    dispatch(fetchProfile());
  }

  function onUpdateBillingInfo() {
    dispatch(updateBillingInfo(form.getFieldsValue()));
  }

  function onFormChange() {
    setEdited(true);
  }

  return (
    <Card title={<h4 className='h4'>{t('billingInformation.title')}</h4>}>
      <Form
        form={form}
        name='billing_information'
        initialValues={{ address, name, description }}
        onFieldsChange={onFormChange}
      >
        <Row gutter={24} className='mb-16' justify='start'>
          <Col xs={24} sm={12} md={6}>
            <Form.Item title='Name' name='name'>
              <Input placeholder={t('billingInformation.legalName')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item title='Country' name={['address', 'country']}>
              <Select showSearch optionFilterProp='children' placeholder={t('title.country')}>
                {regions.map(r => <Option key={r.value} value={r.value}>{r.name} ({r.value})</Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item title='Address 1' name={['address', 'line1']}>
              <Input placeholder={t('billingInformation.adress1')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item title='Address 2' name={['address', 'line2']}>
              <Input placeholder={t('billingInformation.adress2')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item title='City' name={['address', 'city']}>
              <Input placeholder={t('title.city')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item title='Postal Code' name={['address', 'postal_code']}>
              <Input placeholder={t('title.postalCode')} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <Form.Item title='State' name={['address', 'state']}>
              <Input placeholder={t('title.state')} />
            </Form.Item>
          </Col>
        </Row>

        <Space direction={isMobileOrTable ? 'vertical' : 'horizontal'} className='w-100' style={{ justifyContent: 'start' }}>
          <Button
            className='w-100'
            title='Save Information'
            icon={<SaveOutlined />}
            type={edited ? 'primary' : 'default'}
            loading={loading}
            onClick={onUpdateBillingInfo}>
            <Trans i18nKey='title.updateInfo' />
          </Button>
        </Space>
      </Form>
    </Card>
  );
}

BillingInformation.propTypes = {
  authForwarded: PropTypes.object.isRequired,
};
