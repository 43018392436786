import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'antd';
import { InfoCircle } from '@assets/icons';

export default function InfoTooltip({ title, paddingLeft = 0 }) {
  return (
    <Tooltip title={title} className='alignCenter'>
      <InfoCircle style={{ paddingLeft: paddingLeft }}/>
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  paddingLeft: PropTypes.number,
};
