import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-undef
var md5 = require('md5');

import { Avatar as AntdAvatar } from 'antd';

export default function Avatar({ email, size = 'small', src }) {
  let finalSrc = src;
  if (!finalSrc) {
    finalSrc = email ? `https://www.gravatar.com/avatar/${md5(email)}?d=mp` : 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp';
  }

  return <AntdAvatar src={finalSrc} size={size} />;
}

Avatar.propTypes = {
  email: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
